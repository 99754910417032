@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_type';

$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome';

@import '../../../../vendor/assets/stylesheets/jquery.tagit.css';

$dark-blue: #036 !global;
$medium-blue: #1C5798 !global;
$medium-blue-bright: #2674CA !global;
$medium-blue-light: #75AAE6 !global;
$gray-lightest: #fafafa !global;
$gray-light: #767676 !global;
$green-dark: #2c882c !global;
$classic-white: #FFF !global;
$gray-med-dark: #666 !global;
$blue-light: #aacaef !global;
$gray-dark: #333 !global;
$btn-link-disabled-color: lightgrey !global;
$gray-base: #000 !default;
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$font-size-base: 14px !default;
$font-size-h1:   floor(($font-size-base * 2.6)) !default;
$state-danger-bg:  #f2dede !default;
$state-danger-border:  darken(adjust-hue($state-danger-bg, -10), 5%) !default;
$border-radius-large:  6px !default;

$primary: $medium-blue-bright;
$grid-float-breakpoint: 992px;

label.control-label {
  font-weight: bold;
}

a {
  color: $primary;

  .page-link {
    color: $primary;
  }
}

/* Override DataTables pagination color schemes */
.page-item.active .page-link {
  color: #fff !important;
  background-color: $primary !important;
  border-color: $primary !important;
}
.page-item.disabled .page-link {
  color: #8c9094 !important;
  background-color: #fff !important;
  border-color: #d0d4d8 !important;
}

.page-link {
  color: $primary !important;
  background-color: #fff !important;
  border: 1px solid #d0d4d8 !important;
}

.page-link:hover {
  color: #fff !important;
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
}

.content-main {
  padding-left: 50px;
}

html {
  min-height: 100%;
}

header {
  background-color: #000000;
  margin-bottom: 40px;

  .navbar-default {
    width: 100%;

    .navbar-text {
      color: #ffffff;
    }
  }

  #shield-and-logo img{
    margin: 0 auto;
  }

  #psu-shield img{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #logo-old {
    padding-top: 9px;
    padding-left: 30px;
    a {
      color: #ffffff;
      font-size: $font-size-h3;

      &:hover {
        text-decoration: none;
      }
    }
  }

  #account-navigation {
    background-color: $medium-blue-bright;

    .navbar-btn.btn-sm {
      color: #ffffff;
      font-size: $font-size-base;

      &:hover {
        color: #000000;
      }
    }

    .log-div {
      float: right;
    }

    @media (min-width: 768px) {
      .navbar-text.small {
        color: #000000;
      }
    }
    #top-navigation ul {
      li.active a {
        font-weight: bold;
        color: $dark-blue;
      }
    }
  }

  .alert {
    border-radius: 0px;
    padding: 15px 0px;

    .col-xs-12 {
      padding-right: 50px;
    }
  }
}

ul.navbar-list {
  list-style: none;
}

.navbar-list li{
  margin-bottom: -5px;
  .btn {
    width: 8em;
  }
}

.show-tools .nav > li > a {
  overflow-wrap: break-word;
}
#body-container {
  margin-bottom: 2em;
}

#content-container {
  padding-bottom: 20px;
}

#main-navigation {
  margin-top: 20px;
  margin-right: 25px;
  margin-left: 50px;

  .nav {
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .navbar-form {
    padding-bottom: 10px;
  }
}
@media (min-width: 768px) {
  #main-navigation {
    margin-top: 28px;
  }
}

#contact-information {
  @extend .small;

  @media (min-width: 768px) {
    padding: 10px 15px 20px;
  }
}

footer {
  bottom: 0px;
  height: 100%;
  width: 100%;
  padding-top: 20px;

  .navbar-default .navbar-list > li > a {
    color: $blue-light;
    margin-right: 6em;

    &:hover {
      color: #ffffff;
      text-decoration: underline;
    }
  }
  .version {
    color: $gray-light;
  }

  #courtesy-navigation {
    background-color: #000000;
    min-height: 40px;
    margin-bottom: 0px;
    border-radius: 0px;

    .text {
      margin-top: 9px;
    }

    .version {
      padding-top: 4px;
      margin-bottom: 15px;
      float: right;
    }
  }
}

table {
  tr {
    &.odd {
      td {
        background-color: $table-bg-accent;
      }
    }
    &.inactive {
      td {
        color: $gray-light;
      }
    }
  }
}

a.disabled {
  color: $btn-link-disabled-color;
  cursor: not-allowed;

  &:hover {
    color: $btn-link-disabled-color;
  }
}

form {
  .actions {
    margin-top: 30px;
  }
}

.committee-member-fields {
  margin-bottom: 5px;

  .input-group {
    margin-bottom: 10px;

    .input-group-addon {
      background-color: $gray-lightest;
    }

    .form-control {
      border-bottom-right-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
    }
  }
}

.radio_buttons {
  margin-right: 8px;
}

.boolean {
  margin-right: 8px;
}

input[name="submission[access_level]"] {
  margin-right: 8px;
}

.file-link {
  @extend .form-control;
  border-color: $gray-lighter;
  color: blue;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: $link-hover-color;
    background-color: $gray-lighter;
    text-decoration: none;

  }

  &:before {
    @extend .fa;
    @extend .fa-file-text;
    margin: 0px 5px 0px -5px;
  }

  &.danger {
    border-color: $state-danger-border;
    background-color: $state-danger-bg;
  }
}

.nested-fields.panel {
  background-color: #fdfdfd;
}

.nested-fields {
  position: relative;
  min-height: 34px;
  line-height: 34px;

  .row {
    margin-left: 1em;
  }

  .form-group {
    padding-right: 64px;

    &.uploaded-file-link {
      &.can-delete {
        padding-left: 54px;
      }

      &.cannot-delete {
        padding-left: 0;

        .remove_fields {
          display: none;
        }
      }
      padding-right: 0px;

      .file-link {
        @extend .file-link;
      }
    }
  }

  .form-group.submission_committee_members_name {
      padding-right: 0;
      margin-right: 0;
      input {
        width: 100%;
      }
    }

  .committee-member-information {
    label {
      float: left;
      width: 100px;
      text-align: right;
      margin: 2px 10px;
    }

    div.input {
      margin-bottom: 10px;
    }
  }

  .committee-role .form-group {
    margin-bottom: 0.5em;
    width: 50%;
  }

  .committee-role {
    display: flex;
    div {
      padding-right: 0px;
    }
  }

  .committee-details {
    width: 100%;
    div {
      padding-right: 0px;
    }
  }

  .status-row {
    display: flex;
  }

  .submission_committee_members_status {
    width: 50%
  }

  #send_email_reminder {
    margin-left: 50px;
    line-height: inherit;
  }

  .remove-member {
    margin-left: 200px;
  }

  a.remove_fields {
    margin-top: 2.5em;
    font-weight: bold;
    text-size: large;
  }
}

textarea {
  max-width: 100%;
}

.admin {
  margin-bottom: 20px;
}

.form-group.actions .btn {
  margin-bottom: 0.5em;
}

.radio {
  input {
    box-shadow: none;
  }
}

select.disabled {
  cursor: not-allowed;
  background-color: $gray-lighter;
}

.form-group.date {
  @include clearfix();
  margin-bottom: 15px;

  .form-control.date {
    float: left;
  }

  > div {
    select:first-child, select:last-child {
      max-width: 80px;

    }

    select:first-child {
      margin-right: 5px;

      + select {
        max-width: 160px;
        margin-right: 5px;
      }
    }
  }
}

#submission_keywords {
  height: 34px;
}

#format-review-files {
  > div {
    margin-bottom: 10px;
  }
}

.review-group {
  border-radius: 6px;
  padding: 15px 10px;
  border-bottom: 1px solid $gray-lightest;

  &.odd {
    background-color: $gray-lightest;
  }
}

//Redefine bootstrap button colors for better accessibility

.btn-danger {
  background-color: #ce4844;
}

.btn-success {
  background-color: #2e872e;
  border-color:#236D23;
}

.btn-gold {
  background-color: #ffd248;
  border-color: black;
  color: black;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;

    &:hover {
      background-color: #ffd492;
    }
}

@page {
  width: 100%;
  margin: 1em;
}


@media screen {

}

@media (min-width: 768px) {
  .col-sm-2.access {
    padding: 0px;
  }
}

.access-text {
  border-radius: 6px;
  width: 95%;
  margin: 0.5em auto;
  text-align: center;
  color: $classic-white;
}

.nav-pills > li > a {
  color: $classic-white;
}

.nav-pills > li > a:hover {
  background-color: $medium-blue-light;
}

#main-navigation > ul > li{
  background-color: $medium-blue-bright;
  border-radius: $border-radius-large;
  width: 100%;
  margin: 0.5em 0px 0px 0px;
}

.btn-nav {
  color: white;
  width: 100%;
}

.dropdown-item{
  background-color: white;
}

.nav-pills > li.active > a, .nav-pills > li > a:hover, .nav-pills > li.active > a:hover{
  background-color: $blue-light;
  color: $gray-dark;
}

.nav-pills {
  .active {
    background-color: $blue-light;
    color: $gray-dark;
  }
}
.home-text {
  color: $gray-med-dark;
}

.nav > li > a:focus {
  background-color: $medium-blue-bright;
  color: $classic-white;
  border-radius: $border-radius-large;
}

.dropdown-menu {
  background-color: lightgrey;
}

p.hint
{
  color: $gray-med-dark
}

.inventions input
{
  width: auto;
}
#ConfirmModal {
  .modal-dialog
  {
    top: 25%;
  }
  .modal-backdrop
  {
    background-color: #ffffff;
  }
}

#dialog-confirm {
  .modal-dialog
  {
    top: 25%;
  }
  .modal-backdrop
  {
    background-color: #ffffff;
  }
}

//override Bootstrap label-success color (green) to correct contrast errors
.label-success,
.btn-success
{
  background-color: #387f38;
}

.label-info,
.btn-info
{
  background-color: #2c76c7;
}

.label-danger,
.btn-danger
{
  background-color: #d33a35;
}

.label-warning,
.btn-warning
{
  background-color: #F0AD1B;
  color: #333;
}

.caret-right {
  border-left: 4px solid;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
}

form.search-query-form .input-group {
  width: 100%;
  margin-top: 2em;

  span.input-group-addon.for-search-field {
    width: 18%;
    background-color: white;
    border: none;
    padding: 0;
    padding-right: .5em;
    .search_field {
      @extend .form-control;
    }

  }
}

.top-panel-heading {
  .facets-heading {
    font-size: x-large;
  }
}

.access-img img {
  width: 100%;
}

.flash_messages {
  padding-top: 1em;
  width: 100%;
  margin-left: 100px;
  margin-right: 100px;
}


ul.ui-menu.ui-widget.ui-autocomplete{
  z-index: 2;
  background-color: whitesmoke;
  list-style-type: none;
  border: 1px solid #000;
  width: 50%;
  padding: .5em;
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  display: block;
  color: black;

}

div.ui-helper-hidden-accessible {
  display: block;
  display: none;
}

.ui-state-active {
  display: block;
  background-color: white;
  font-weight: bolder;
  width: 100%;
  padding: .25em;
  border-radius: 5px;
  box-shadow: inset 0px 0px 1px 1px slategrey;
}

//This removes dots from under askterisk in required fields
form abbr[title] {
  border-bottom: none !important;
  text-decoration: none !important;
}

.required-asterisk{
  float: right;
}
a.cancel-link{
  font-size: medium;
  vertical-align: middle;
}

.confidential-alert {
  color: red;
}

.xxs {
  padding: 0;
  margin: 0;
  width: 1%;
}

//Styles for main page
.btn-main{
  background-color: $medium-blue-bright;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-container{
  padding-bottom: 20px;
}

.author-col{
  margin-left: 25%;
  margin-right: 2%;
}

.admin-col{
  background-color: #000000;
  border-radius: 5px;
  margin-top: 60px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-top: 20px;
  width: 285px;
}

.admin-box > li {
  padding-left: 22px;
  padding-right: 35px;
  list-style-type: none;
}

.admin-box > div {
  color: white;
}

.about-page {
  margin-left: 8%;
}

.submission_info {
  div {
    padding-bottom: 8px;
  }
}

.access-level-warning {
  color: red;
}

.access-level-open {
  color: green;
}

.committee_reviews {
  padding-top: 20px;
}

.review-message {
  width: 600px;
}

.review-complete {
  color: green;
}

.review-rejected {
  color: red;
}

.altering-submission {
  padding-top: 10px;
  font-style: italic;
}

.status_select {
  width: 200px;
}

.notes_select {
  width: 320px;
  min-height: 124px;
}

#committee_member_table_audit {
  width: 100%;
}

.submission_committee_members_federal_funding_used {
  display: flex;

  .radio {
    margin-right: 10px;
  }
}

.vertical-radios {
  padding-left: 0px;

  span {
    display: block;
  }
}

//Docs page styles
img.docs-image {
  border: 10px solid #ddd;
  border-radius: 5px;
}

// Admin edit submission styles
a.contact-support-link {
  display: inline-block;
  padding-bottom: 20px;
}

// Approver styles
.select-reviews {
  margin-left: 25px;
}

.approver-review {
  margin-left: 25%;
}

#approver-committees-index span {
  display:none;
}

.approver-index {
  margin-left: 25%;
}

.contact-form {
  margin-left: 17%;
}

.special-committee {
  margin-left: 25%
}

// network graph styles
.link {
  stroke: #999;
  stroke-opacity: 0.2;
}

.node {
  stroke: #fff;
  stroke-width: 1.5px;
}

.node circle {
  fill: blue;
}

.node rect {
  fill: green;
  width: 80px;
  height: 40px;
  position: middle;
}

.network-graph {
  width: 100vw;
  height: 100vh;
}
